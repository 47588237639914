.c-confirmation {
  height: 100vh;
  color: var(--royal-blue);
}

.confirmation__title {
  color: var(--royal-blue);
  font-size: 3.6rem;
  font-weight: $font-weight-heavy;
  margin-bottom: $global-spacing * 2.4;
}

.confirmation__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.confirmation__content {
  text-align: center;
  max-width: 420px;
  margin: 0 auto;
}

.confirmation__icon {
  margin-bottom: $global-spacing * 2.4;
}

.confirmation__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmation__button {
  margin-bottom: $global-spacing;
}
