/*----------------------------------*\
  #COLORS
\*----------------------------------*/

@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root,
:root.bluebeem {
  --royal-blue: #0F61D8;
  --royal-blue--rgb: #{hexToRGB(#0F61D8)};
  --secondary: #ffffff;
  --secondary--rgb: #{hexToRGB(#ffffff)};
  --turquoise-green: #11CCA8;
  --turquoise-green--rgb: #{hexToRGB(#11CCA8)};
  --cloud-grey: #DBDBDB;
  --navy-blue: #09326D;
  --white: #ffffff;
  --white--rgb: #{hexToRGB(#ffffff)};
  --charcoal-black: #3D3D3D;

  --main-layout: var(--royal-blue);
  --normal-text: var(--charcoal-black);
  --normal-text--rgb: #{hexToRGB(#3D3D3D)};
  --left-side-text: #ffffff;
  --right-layout: #ffffff;
  --price-background: var(--turquoise-green);
  --button-background: var(--turquoise-green);
  --list-item-bullet-color: var(--turquoise-green);
}

:root.brinks {
  --royal-blue: #0A4A8E;
  --royal-blue--rgb: #{hexToRGB(#0A4A8E)};
  --turquoise-green: #5cb8a5;
  --turquoise-green--rgb: #{hexToRGB(#5cb8a5)};
  --cloud-grey: #DBDBDB;
  --navy-blue: #0A4A8E;
  --white: #ffffff;
  --white--rgb: #{hexToRGB(#ffffff)};
  --charcoal-black: #3D3D3D;

  --main-layout: #0A4A8E;
  --normal-text: var(--charcoal-black);
  --normal-text--rgb: #{hexToRGB(#3D3D3D)};
  --left-side-text: #ffffff;
  --right-layout: #ffffff;
  --price-background: var(--royal-blue);
  --button-background: #ffc528;
  --list-item-bullet-color: var(--button-background);
}


// // Greyscale
$color-black: #000000;
$color-grey: var(--cloud-grey);

// Status colors
$color-success: #00c09a;
$color-info: #29aed6;
$color-warning: #ffc400;
$color-danger: #e23939;

// Core color settings - leftovers used in html files
$color-divider-line: var(--cloud-grey);

// Social color palette
$color-social-twitter: #00aced;
$color-social-facebook: #3b5998;
$color-social-googleplus: #dd4b39;
$color-social-pinterest: #cb2027;
$color-social-linkedin: #007bb6;
$color-social-youtube: #bb0000;
$color-social-vimeo: #aad450;
$color-social-instagram: #517fa4;
$color-social-flickr: #ff0084;
$color-social-dribbble: #ea4c89;
$color-social-whatsapp: #4dc247;

// List of social channels with their colors you can use to loop through
$color-socials-map: (
  'twitter': $color-social-twitter,
  'facebook': $color-social-facebook,
  'linkedin': $color-social-linkedin,
  'youtube': $color-social-youtube,
);
