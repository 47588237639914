.c-toast {
  position: fixed;
  z-index: 5;
  top: 30px;
  left: 50%;
  width: calc(100% - 30px);
  transform: translateX(-50%);
}

.toast__item {
  min-height: 50px;
  width: 100%;
  margin-bottom: $global-spacing-xs;
  padding: $global-spacing-xs * 1.5;
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba($color-black, 0.3);

  &--success {
    background-color: $color-success;
  }

  &--warning {
    background-color: $color-warning;
  }

  &--error {
    background-color: $color-danger;
  }
}

.toast__message {
  color: var(--white);
  margin: 0;
}
