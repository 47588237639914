@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: $font-weight-heavy;
  src: url('../../assets/fonts/Ageo-Heavy.otf') format('truetype');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: $font-weight-extrabold;
  src: url('../../assets/fonts/Ageo-ExtraBold.otf') format('truetype');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url('../../assets/fonts/Ageo-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: $font-weight-semibold;
  src: url('../../assets/fonts/Ageo-SemiBold.otf') format('truetype');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: $font-weight-medium;
  src: url('../../assets/fonts/Ageo-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: $font-weight-regular;
  src: url('../../assets/fonts/Ageo-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: $font-weight-light;
  src: url('../../assets/fonts/Ageo-Light.otf') format('truetype');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: $font-weight-ultralight;
  src: url('../../assets/fonts/Ageo-Thin.otf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: $font-weight-heavy;
  src: url('../../assets/fonts/Nunito-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: $font-weight-extrabold;
  src: url('../../assets/fonts/Nunito-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url('../../assets/fonts/Nunito-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: $font-weight-semibold;
  src: url('../../assets/fonts/Nunito-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: $font-weight-medium;
  src: url('../../assets/fonts/Nunito-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: $font-weight-regular;
  src: url('../../assets/fonts/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: $font-weight-light;
  src: url('../../assets/fonts/Nunito-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: $font-weight-ultralight;
  src: url('../../assets/fonts/Nunito-ExtraLight.ttf') format('truetype');
}

// Nunito Sans

@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: $font-weight-heavy;
  src: url('../../assets/fonts/NunitoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: $font-weight-extrabold;
  src: url('../../assets/fonts/NunitoSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url('../../assets/fonts/NunitoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: $font-weight-semibold;
  src: url('../../assets/fonts/NunitoSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: $font-weight-medium;
  src: url('../../assets/fonts/NunitoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: $font-weight-regular;
  src: url('../../assets/fonts/NunitoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: $font-weight-light;
  src: url('../../assets/fonts/NunitoSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: $font-weight-ultralight;
  src: url('../../assets/fonts/NunitoSans-ExtraLight.ttf') format('truetype');
}
