// Style
$button-spacing: $global-spacing;
$button-border-radius: 30px;

// Colors
$button-font-color: $color-black;
$button-border-color: var(--turquoise-green);
$button-icon-color: $button-font-color;
$button-background-color: var(--button-background);
$button-font-color-hover: var(--white);
$button-icon-color-hover: $button-font-color-hover;
$button-background-color-hover: $button-border-color;

// Transitions
$button-transition-time: 300ms;
$button-transition-ease: $ease-in-out-quad;

@mixin button {
  @include button--base;
  padding: $button-spacing* 1.45 $button-spacing * 3.2;
  border-radius: $button-border-radius;
  border: 3px solid $button-border-color;
  background-color: $button-background-color;
  color: $button-font-color;
  font-weight: $font-weight-bold;
  min-width: 190px;

  .button__label {
    display: block;
  }

  svg {
    fill: $button-font-color;
    transition: $button-transition-time $button-transition-ease;
    transition-property: fill;
  }

  @include hover-focus {
    color: $button-font-color-hover;
    background-color: $button-background-color-hover;

    &::before {
      display: none;
    }

    svg {
      fill: $button-font-color-hover;
    }
  }

  @include keyboard-focus {
    color: $button-font-color-hover;
    background-color: makeDarker($button-background-color-hover,50%);

    svg {
      fill: $button-font-color-hover;
    }
  }

  &[disabled] {
    background-color: rgba(var(--white--rgb), 0.1);
    color: rgba(var(--white--rgb), 0.2);
    pointer-events: none;

    &.c-button--disabled-tertiary {
      background-color: rgba(var(--royal-blue--rgb), 0.1);
      color: rgba(var(--royal-blue--rgb), 0.6);
    }
  }
}
