.address__modal {
  display: block;
  padding-top: $global-spacing-md;
}

.address__modal-row {
  display: flex;
  flex-wrap: wrap;

  .form__item {
    width: 100%;
  }

  @include screen-tablet-portrait-and-bigger {
    flex-wrap: nowrap;

    .form__item {
      width: calc(50% - #{$global-spacing});

      &:not(:last-child) {
        margin-right: $global-spacing * 2;
      }
    }
  }
}

.address__modal-row-full {
  display: flex;
  flex-wrap: wrap;

  .form__item {
    width: 100%;
  }

  @include screen-tablet-portrait-and-bigger {
    flex-wrap: nowrap;

    .form__item {
      width: 100%;

      &:not(:last-child) {
        margin-right: $global-spacing * 2;
      }
    }

    .radio__smaller_label {
      font-size: 70%;
    }
  }
}
