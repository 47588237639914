.main-test-header {
  a {
    color: #539dc2;
  }
}

.icon-block {
  max-width: 150px;
  display: inline-block;
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px;
}
