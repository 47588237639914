/*------------------------------------*\
  #RICH TEXT
\*------------------------------------*/

// Custom scope for RTE styles.
.s-rich-text {
  color: var(--royal-blue);

  .first-character {
    float: left;
    font-size: 8.8rem;
    line-height: 1;
    font-weight: $font-weight-extrabold;
    padding: 0 $global-spacing-xs * 0.8 0 0;
  }

  p:not(:last-child) {
    margin-bottom: $global-spacing-xlg;
  }

  a {
    color: var(--secondary);
    font-weight: $font-weight-bold;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--royal-blue);
    font-weight: $font-weight-semibold;
    margin-bottom: $global-spacing-xs * 2.5;
  }

  ul {
    list-style: none;
    padding: 0;
    margin-bottom: $global-spacing-xs * 2.5;

    li {
      position: relative;
      padding-left: $global-spacing-xs * 2.5;

      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--secondary);
      }
    }
  }

  ol {
    list-style: none;
    padding: 0;
    counter-reset: ol-counter;
    margin-bottom: $global-spacing-xs * 2.5;

    li {
      position: relative;
      padding-left: $global-spacing-xs * 2.5;
      counter-increment: ol-counter;

      &::before {
        content: counter(ol-counter) '.';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }
    }
  }
}
