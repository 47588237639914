$ubo-text-color: var(--normal-text--rgb);
$ubo-button-color: var(--button-background);
$ubo-verified-color: var(--turquoise-green--rgb);

.c-ubo {
    padding: $global-spacing-xs * 2.5;
    margin-bottom: $global-spacing-xs;
    border-radius: 8px;
    width: 100%;
    outline: none;
    transition: transform 300ms ease-in-out;
    display: flex;
    align-items: center;
    border: 1px solid rgba($ubo-text-color, 0.24);

    &.is--verified {
        background-color: rgba($ubo-verified-color, 0.16);
        border: none;
    }

    &.is--failed {
        background-color: rgba($color-danger, 0.16);
        border: none;
    }

    @include hover-focus {
        transform: scale3d(1.02, 1.02, 1);
    }
}

.ubo__icon-wrapper {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: auto;
    display: none;

    .is--verified & {
        display: block;
    }
}

.ubo__icon {
    path {
        fill: var(--secondary);
    }
}

.ubo__title {
    font-weight: $font-weight-extrabold;
    color: var(--royal-blue);
    margin-bottom: 0;
    text-align: left;
}

.ubo__buttons {
  margin-top: 10px;
}

.ubo__button {
  font-size: 1.6rem;
  color: $ubo-text-color;
  text-decoration: underline;
  margin-right: 10px;
}

.ubo__button--verify {
  margin-top: 10px;
  padding: 7px 14 px;

  svg {
    margin-left: 5px;
    width: 6px;
  }

  path {
    fill: var(--white);
  }
}
