.c-verify {
  display: flex;
  align-items: center;
  padding: $global-spacing-xs * 2.5;
  margin-bottom: $global-spacing-xs;
  background-color: rgba(var(--turquoise-green--rgb), 0.16);
  border-radius: 8px;
  width: 100%;
  outline: none;
  transition: transform 300ms ease-in-out;

  > * {
    margin: 0;
  }

  &.error {
    background-color: rgba($color-danger, 0.16);
  }

  @include hover-focus {
    transform: scale3d(1.02, 1.02, 1);
  }
}

.verify__icon-wrapper {
  width: 36px;
  height: 36px;
  background-color: var(--turquoise-green);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
  margin-right: $global-spacing-xs;
  flex-shrink: 0;
}

.verify__icon {
  path {
    fill: var(--white);
  }
}

.success-info {
  padding: $global-spacing-xs * 2.5 0;
}

.pending-info {
  padding: $global-spacing-xs * 2.5 0;
}

.verify__content {
  font-weight: $font-weight-extrabold;
  color: var(--charcoal-black);
  margin-bottom: 0;
  text-align: left;
}
