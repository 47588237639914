// Style
$button-secondary-spacing: $global-spacing;
$button-secondary-border-radius: 3px;

// Colors
$button-secondary-font-color: var(--white);
$button-secondary-icon-color: $button-secondary-font-color;
$button-secondary-background-color: #ffc528;
$button-secondary-font-color-hover: var(--white);
$button-secondary-icon-color-hover: $button-secondary-font-color-hover;
$button-secondary-background-color-hover: darken($button-secondary-background-color,10%);

// Transitions
$button-secondary-transition-time: 300ms;
$button-secondary-transition-ease: $ease-in-out-quad;

@mixin button--secondary {
    @include button;
    background-color: $button-secondary-background-color;
    padding: 10px 15px;
    border: 0;
    min-width: 0;
    color: $button-secondary-font-color;

    svg {
        fill: $button-secondary-font-color;
    }

    @include hover-focus {
        color: $button-secondary-font-color-hover;
        background-color: $button-secondary-background-color-hover;
    }

    @include keyboard-focus {
        color: $button-secondary-font-color-hover;
        background-color: makeLighter($button-secondary-background-color-hover, 50%);

        svg {
            fill: $button-secondary-font-color-hover;
        }
    }
}
