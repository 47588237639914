.form__item {
  @include fluid-type(4, 2, margin-bottom);

  &--half {
    width: calc(50% - 10px);
    display: inline-block;

    @include screen-tablet-landscape-and-smaller {
      width: 100%;
    }
  }

  &--no-margin {
    .form__item {
      margin: 0;
    }
  }

  &--no-margin-lg {
    .form__item {
      @include screen-laptop-and-bigger {
        margin: 0;
      }
    }
  }
}
