// sass-lint:disable no-vendor-prefixes, no-important

html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 320px;
    min-height: 100%;
}

body {
    font-family: $font-stack-primary;
}

html {
    color: var(--normal-text);
    background-color: var(--white);
    font-size: $font-size-root;
    min-height: 100%;

    &.no--scroll {
        overflow-y: hidden !important;
    }
}

body {
    @include fluid-type($font-size-body, $font-size-body*0.875);
    line-height: $font-line-height-body;
    hyphens: auto;
    word-break: normal;
}
