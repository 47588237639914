$loader-color: var(--royal-blue);

.c-loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  width: 100vw;
  height: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--white--rgb), 0.7);
  transition: opacity 300ms ease-in-out;

  &--hidden {
    z-index: -1;
    opacity: 0;
  }

  &--visible {
    opacity: 1;
  }
}

.loader__ring {
  position: relative;
  width: $global-spacing-lg * 2;
  height: $global-spacing-lg * 2;

  div:nth-child(1) {
    animation-delay: -450ms;
  }

  div:nth-child(2) {
    animation-delay: -300ms;
  }

  div:nth-child(3) {
    animation-delay: -150ms;
  }
}

.loader__part {
  position: absolute;
  width: $global-spacing-xs * 6.4;
  height: $global-spacing-xs * 6.4;
  border: $global-spacing-xs * 0.4 solid $loader-color;
  border-color: $loader-color transparent transparent transparent;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
