// Style
$button-primary-spacing: $global-spacing;
$button-primary-border-radius: 3px;

// Colors
$button-primary-font-color: var(--royal-blue);
$button-primary-icon-color: $button-primary-font-color;
$button-primary-background-color: var(--white);
$button-primary-background-color-hover: var(--turquoise-green);
$button-primary-font-color-hover: var(--white);
$button-primary-icon-color-hover: $button-primary-font-color-hover;

// Transitions
$button-primary-transition-time: 300ms;
$button-primary-transition-ease: $ease-in-out-quad;

@mixin button--primary {
  @include button;
  background-color: $button-primary-background-color;
  color: $button-primary-font-color;

  svg {
    fill: $button-primary-font-color;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid $button-primary-background-color-hover;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 30px;
    transition: transform 0.2s ease-out;
    transform: translate(0 ,0)
  }

  @include hover-focus {
    color: $button-primary-font-color-hover;
    background-color: $button-primary-background-color-hover;
    z-index: 1;

    &:after {
      transform: translate(12px, 12px);
    }
  }

  @include keyboard-focus {
    color: $button-primary-font-color-hover;
    background-color: $button-primary-background-color-hover;

    svg {
      fill: $button-primary-font-color-hover;
    }
  }
}
