$list-item-color: var(--list-item-bullet-color);

.c-list {
  list-style-type: none;
  padding: 0;
}

.list__item {
  position: relative;
  padding-left: $global-spacing-xs * 2.5;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $list-item-color;
  }
}
