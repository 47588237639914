.c-form-header {
  margin-bottom: $global-spacing-xlg;
}

.form-header__prev {
  position: absolute;
  top: 24px;
  left: 24px;
  padding: $global-spacing-xs * 1.5 $global-spacing-sm;
  display: flex;
  align-items: center;

  @include hover-focus {
    .form-header__prev-icon {
      transform: translate3d(-5px, 0, 0);
    }
  }

  @include screen-tablet-portrait-and-smaller {
    padding: 0;
  }
}

.form-header__prev-icon {
  margin-right: $global-spacing-xs;
  transition: transform 300ms ease-in-out;

  path {
    fill: var(--left-side-text);
  }
}

.form-header__prev-label {
  color: var(--left-side-text);
  font-weight: $font-weight-extrabold;
  z-index: 4;

  @include screen-tablet-portrait-and-smaller {
    display: none;
  }
}

.form-header__status {
  display: flex;
  align-items: center;
  margin-bottom: $global-spacing-xs * 2.5;

}

.form-header__status-icon {
  margin-right: $global-spacing-xs;
  path { fill: var(--turquoise-green) }
}

.form-header__status-text {
  color: var(--turquoise-green);
}

.form-header__indicator {
  color: var(--cloud-grey);
  text-transform: uppercase;
  font-size: $font-size-small;
  font-weight: $font-weight-extrabold;
  margin-bottom: $global-spacing-xs * 2.5;
}

.form-header__title {
  @include h2;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  font-size: $font-size-h2;
  color: var(--left-side-text);

  &--has-sub {
    margin-bottom: $global-spacing-xs * 2.5;
  }
}

.form-header__text {
  font-size: $font-size-h5;
  color: var(--left-side-text);
  margin-bottom: 0;
}
