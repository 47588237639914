@mixin p {
    margin-bottom: 1em;
    font-size: $font-size-body;

    + p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

p {
    @include p;
}
