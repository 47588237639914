// Style
$button-ghost-spacing: $global-spacing;
$button-ghost-border-width: 2px;
$button-ghost-border-radius: 3px;

// Transitions
$button-ghost-transition-time: $button-transition-time;
$button-ghost-transition-ease: $button-transition-ease;

@mixin button--ghost-secondary {
  @include button;
  padding: 0;
  line-height: 43px;
  background: transparent;
  transition-property: color, box-shadow;
  color: var(--royal-blue);
  font-weight: $font-weight-heavy;

  path {
    fill: currentColor;
  }

  @include hover-focus {
    color: var(--royal-blue);
    background-color: transparent;
    box-shadow: 0 0 0 $button-ghost-border-width transparent inset;
  }

  @include keyboard-focus {
    background-color: makeLighter(transparent, 50%);
    box-shadow: 0 0 0 $button-ghost-border-width makeLighter(transparent, 50%) inset;
  }
}
