$select-color: var(--charcoal-black);
$select-border-radius: 32px;

.c-select {
  width: 100%;
}

.select__input {
  appearance: none;
  padding: $global-spacing-xs * 1.5 $global-spacing-sm;
  line-height: $font-line-height-h2;
  width: 100%;
  border-width: 3px;
  border-color: $select-color;
  color: $select-color;
  background-color: transparent;
  border-radius: $select-border-radius;

  &[disabled] {
    opacity: 0.5;
  }

  &:invalid {
    outline: none;
  }

  .form__item--error & {
    border-color: $color-danger;
  }
}

.select__input--white {
  border-color: var(--white);
  color: var(--white);

  option {
    color: $select-color;
  }
}

.select__wrapper {
  position: relative;
  display: block;
  width: 100%;

  svg {
    position: absolute;
    top: 40%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.select__label-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: $global-spacing-sm;

  .c-tooltip {
    padding-left: $global-spacing;
  }
}

.select__label-title {
  display: block;
  font-size: $font-size-body;
  line-height: $font-line-height-body;
  font-weight: $font-weight-extrabold;
  color: $select-color;
}

.select__label-subtitle {
  display: block;
  font-weight: $font-weight-regular;
}

.select__error {
  margin: $global-spacing-sm 0 0;
  color: $color-danger;
  font-size: $font-size-small;
}
