.c-company-tile {
  padding: $global-spacing-xs * 1.5 $global-spacing-xs * 2.5 $global-spacing-xs * 2.5;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  transition: transform 300ms ease-in-out;

  &--primary {
    background-color: rgba(var(--white--rgb), 0.08);
    color: var(--left-side-text);
  }

  &--secondary {
    background-color: rgba(var(--white--rgb), 0.2);
    color: var(--left-side-text);
    margin-bottom: 40px;
  }

  @include hover-focus {
    transform: scale3d(1.02, 1.02, 1);
  }
}

.company-tile__slider-wrapper {
  .slick-slider {
    width: calc(100% + #{$global-spacing-xs * 2.4});
  }
}

.company-tile__slide {
  margin-right: $global-spacing-xs;
}

.company-tile__button-wrapper {
  padding-top: $global-spacing;
}

.company-tile__button {
  color: var(--button-background);
  font-weight: $font-weight-bold;
  display: inline-block;

  svg > * {
    fill: var(--button-background);
  }
}

.company-tile__slider-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $global-spacing-xs * 2.5;
}

.company-tile__slider-button {
  display: flex;
  align-items: center;
  color: var(--left-side-text);
  font-weight: $font-weight-bold;

  &:disabled {
    pointer-events: none;
    opacity: 0.8;
  }

  > span {
    margin: 1px 10px 0 10px;
  }

  @include screen-tablet-portrait-and-smaller {
    > span {
      display: none;
    }
  }
}

.company-tile__slider-dots {
  display: flex;
  align-items: center;
  justify-items: center;
  list-style-type: none;
  padding-left: 0;
}

.company-tile__slider-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(var(--white--rgb), 0.24);
  transition: background-color 300ms ease-in-out;

  &:not(:last-of-type) {
    margin-right: $global-spacing-xs * 0.8;
  }

  &--active {
    background-color: var(--button-background);
  }
}
