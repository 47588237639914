$radio-background-color: var(--button-background);
$radio-label-color: var(--normal-text);

.radio__input {
  @include sr-only;

  &:checked {
    & + label {
      color: var(--white);
      background-color: $radio-background-color;
    }
  }

  &[disabled] {
    & + label {
      cursor: default;
      opacity: 0.5;
    }
  }

  &:invalid {
    outline: none;
  }
}

.radio__label-wrapper {
  display: flex;
  justify-content: space-between;

  .c-tooltip {
    padding-left: $global-spacing;
  }
}

.radio__label-title {
  display: block;
  font-size: $font-size-body;
  line-height: $font-line-height-body;
  font-weight: $font-weight-extrabold;
  color: $radio-label-color;
  margin-bottom: $global-spacing-sm;
}

.radio__label-subtitle {
  display: block;
  font-weight: $font-weight-regular;
}

.radio__label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  background-color: rgba(var(--royal-blue--rgb), 0.08);
  color: $radio-label-color;
  font-size: $font-size-body;
  line-height: $font-size-body;
  font-weight: $font-weight-bold;
  padding: $global-spacing $global-spacing * 2.5;
  border-radius: $global-spacing * 2.5;
  margin-right: $global-spacing;
  transition: color 100ms $ease-in-cubic, background-color 100ms $ease-in-cubic;

  .form__item--error & {
    border: 1px solid $color-danger;
  }

  .form__item--primary & {
    label {
      border-color: var(--royal-blue);
    }
  }

  .form__item--secondary & {
    &::after {
      border-color: var(--royal-blue--rgb);
    }
  }

  .form__item--tertiary & {
    color: var(--royal-blue--rgb);

    &::after {
      border-color: var(--royal-blue--rgb);
    }
  }
}

.radio__error {
  margin: $global-spacing-sm;
  color: $color-danger;
  font-size: $font-size-small;
}
