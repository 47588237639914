.product-input__wrapper {
  position: relative;
}

.product-input__label-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: $global-spacing-sm;

  .c-tooltip {
    padding-left: $global-spacing;
  }
}

.product-input__label-title {
  display: block;
  font-size: $font-size-body;
  line-height: $font-line-height-body;
  font-weight: $font-weight-extrabold;
  color: var(--royal-blue);
}

.product-input__label-subtitle {
  display: block;
  font-weight: $font-weight-regular;
}

.product-input__input {
  @include placeholder(var(--navy-blue));
  display: block;
  appearance: none;
  width: 100%;
  background-color: transparent;
  transition: border-color $input-transition-time $input-transition-ease;
  padding: $global-spacing;
  border: $input-border-width solid rgba(var(--royal-blue--rgb), 0.24);
  border-radius: $input-border-radius;
  color: var(--royal-blue);

  .form__item--error.form__item--tertiary & {
    border-color: $color-danger;
  }

  .form__item--error & {
    border-color: $color-danger;
  }
}

.input__clear,
.input__tooltip {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}

.product-input__error {
  margin: $global-spacing-sm 0 0;
  color: $color-danger;
  font-size: $font-size-small;
}
