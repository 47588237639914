.c-location {
  border: 1px solid rgba(var(--royal-blue--rgb), 0.24);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
}

.c-location--error {
  background-color: rgba($color-danger, 0.16);
  border: 1px solid transparent;
}

.location__buttons {
  display: flex;
  flex-wrap: wrap;
}

.location__button {
  color: var(--button-background);
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-right: 25px;

  svg path {
    fill: var(--button-background);
  }
}

.location__button-text {
  display: block;
  padding-right: 10px;
}

.location__title {
  color: var(--navy-blue);
  margin-bottom: 24px;
  font-size: 2.4rem;
}

.location__data {
  color: var(--charcoal-black);
  margin-bottom: 16px;
}

.location__error {
  margin-bottom: 20px;
  color: $color-danger;
}
