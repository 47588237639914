// Style
$button-icon-spacing: $global-spacing * 4;

// Transitions
$button-icon-transition-time: 300ms;
$button-icon-transition-ease: $ease-in-out-quad;

@mixin button--icon {
  padding-right: $button-icon-spacing;
  position: relative;

  .button__icon {
    display: block;
    position: absolute;
    top: calc(50% - #{$global-spacing});
    bottom: 0;
    right: $button-spacing * 1.5;
    transform: translate3d(0, -50%, 0);
    transition: $button-icon-transition-time $button-icon-transition-ease;
    transition-property: transform;
  }

  .button__icon--loading {
    animation: spin 2s infinite;
  }

  @include hover-focus {
    .button__icon {
      transform: translateX($global-spacing/2);
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  11% {
    transform: rotate(40deg);
  }

  22% {
    transform: rotate(80deg);
  }

  33% {
    transform: rotate(120deg);
  }

  44% {
    transform: rotate(160deg);
  }

  55% {
    transform: rotate(200deg);
  }

  66% {
    transform: rotate(240deg);
  }

  77% {
    transform: rotate(280deg);
  }

  88% {
    transform: rotate(320deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
