.c-selected-package {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  border: 1px solid rgba(var(--royal-blue--rgb), 0.24);

  @include screen-mobile-plus-and-smaller {
    border-radius: 0;
    border: 0;
  }
}

.selected-package__button {
  display: flex;
  justify-content: space-between;
  background-color: var(--royal-blue);
  color: var(--white);
  font-weight: $font-weight-bold;
  padding: $global-spacing $global-spacing-sm;
  width: 100%;
  text-align: left;
}

.selected-package__button-title {
  svg {
    margin-right: $global-spacing;
  }
}

.selected-package__state-indicator {
  right: $global-spacing;
  transform: rotate(0deg);
  transition: transform 200ms $ease-in-out-cubic;
  display: none;

  & svg > * {
    fill: var(--white);
  }

  .c-selected-package--active & {
    transform: rotate(180deg);
  }

  @include screen-mobile-plus-and-smaller {
    display: inline-block;
  }
}

.selected-package__package-tiles {
  > :not(:last-of-type)::after {
    position: absolute;
    content: '';
    width: calc(100% - (#{$global-spacing-lg}));
    left: $global-spacing-sm;
    bottom: 0;
    display: block;
    height: 1px;
    background-color: rgba(var(--royal-blue--rgb), 0.24);
  }
}

.selected-package__package-tile {
  position: relative;
  display: block;
  padding: $global-spacing-sm;
}

.selected-package__package-tile-title {
  margin-bottom: $global-spacing-xs * 1.5;
  color: var(--navy-blue);
}

.selected-package__package-tile-list {
  list-style-type: none;
  padding-left: $global-spacing-sm;
  font-size: $font-size-body;
  color: var(--royal-blue);

  li {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      left: -$global-spacing-sm;
      top: calc(#{($font-line-height-body) / 2}em - 4px);
      display: inline-block;
      border-radius: 100%;
      background-color: var(--secondary);
    }
  }
}

.selected-package__price {
  display: flex;
  flex-direction: column;
  background-color: var(--price-background);
  padding: $global-spacing-sm;
  color: var(--white);

  div {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;

    span:last-of-type {
      font-weight: $font-weight-bold;
    }
  }
}

.selected-package__package-title {
  display: flex;
  flex-wrap: wrap;
  padding: $global-spacing-sm $global-spacing-sm 0;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(var(--royal-blue--rgb), 0.08);

  .selected-package__package-tile-title {
    margin-bottom: $global-spacing;
  }

  &.brinks-styling {
    background-color: transparent;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: calc(100% - (#{$global-spacing-lg}));
      left: $global-spacing-sm;
      bottom: 0;
      display: block;
      height: 1px;
      background-color: rgba(var(--royal-blue--rgb), 0.24);
    }
  }
}

.selected-package__change-package {
  color: var(--navy-blue);
  margin-bottom: $global-spacing-sm;

  & svg {
    width: 16px;
    height: 19px;
    margin-left: $global-spacing-xs;

    * {
      fill: var(--navy-blue);
    }
  }
}

.selected-package__package-more-info {
  color: var(--navy-blue);
  font-size: $font-size-body;
  font-weight: $font-weight-regular;
  text-decoration: underline;
  display: block;
  margin-bottom: $global-spacing-sm;

  &::before {
    display: none;
  }
}

.selected-package__package-subtile {
  margin-bottom: 10px;
  list-style-type: none;
}

.selected-package__package-subtile-title {
  color: var(--navy-blue);
  font-size: 2rem;
}
