.c-verified-tile {
  background-color: var(--cloud-grey);
  border-radius: 8px;
  padding: $global-spacing-xs * 1.6 $global-spacing-xs * 2.4 $global-spacing-xs * 2.4;
  margin-bottom: $global-spacing-xs * 2.4;

  div:last-child {
    margin-bottom: 0;
  }
}

.verified-tile__title {
  font-size: $font-size-body;
  font-weight: $font-weight-extrabold;
  color: var(--royal-blue);
  margin-bottom: $global-spacing-sm;
}
