.c-autocomplete-list {
  list-style: none;
  padding: 0;
  margin-top: $global-spacing-xs;
}

.autocomplete-list__item {
  transform-origin: top center;
  transition-duration: 100ms;

  &:not(:last-child) {
    margin-bottom: $global-spacing-xs * 2.5;
  }
}

.autocomplete-list__button {
  width: 100%;
  text-align: left;
}
