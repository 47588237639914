// Style
$button-ghost-spacing: $global-spacing;
$button-ghost-border-width: 2px;
$button-ghost-border-radius: 3px;

// Colors
$button-ghost-font-color: var(--normal-text);
$button-ghost-icon-color: $button-ghost-font-color;
$button-ghost-background-color: transparent;
$button-ghost-font-color-hover: var(--white);
$button-ghost-icon-color-hover: $button-ghost-font-color-hover;
$button-ghost-background-color-hover: var(--button-background);

// Transitions
$button-ghost-transition-time: $button-transition-time;
$button-ghost-transition-ease: $button-transition-ease;

@mixin button--outline-secondary {
  @include button--secondary;
  background: transparent;
  box-shadow: 0 0 0 $button-ghost-border-width $button-ghost-font-color inset;
  transition-property: color, background-color, box-shadow;
  color: $button-ghost-font-color;
  font-weight: $font-weight-heavy;

  @include hover-focus {
    color: $button-ghost-font-color-hover;
    background-color: $button-ghost-background-color-hover;
    box-shadow: 0 0 0 $button-ghost-border-width $button-ghost-background-color-hover inset;
  }

  @include keyboard-focus {
    background-color: rgba($button-ghost-background-color-hover, 50%);
    box-shadow: 0 0 0 $button-ghost-border-width rgba($button-ghost-background-color-hover, 50%)
      inset;
  }
}
