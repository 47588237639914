// sass-lint:disable class-name-format

//THIRD PARTY PACKAGE STYLING
.ReactModal__Overlay {
  background-color: rgba(var(--royal-blue--rgb), 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  max-height: 100vh;
  transition: opacity 200ms 50ms;
  transition-timing-function: $ease-out-cubic;
  z-index: 99;
  display: flex;
  align-items: center;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Content {
  width: 100%;
  margin: auto;
  overflow: auto;
  outline: none;
  padding: $global-spacing-md;
  position: relative;
  bottom: 0;
  transition: opacity 300ms, transform 300ms;
  transition-timing-function: $ease-in-out-cubic;
  opacity: 0;
  transform: translateY(40px);

  @include screen-mobile-plus-and-bigger {
    width: 500px;
    max-width: 90%;
  }

  &--after-open {
    opacity: 1;
    transform: translateY(0);
  }

  &--before-close {
    opacity: 0;
    transform: translateY(40px);
  }
}
// sass-lint:enable class-name-format

.modal__content-wrapper {
  position: relative;
  background: var(--white);
  padding: $global-spacing-md;
  border-radius: 20px;
}

.modal__title {
  color: var(--royal-blue);
}

.modal__close-button {
  position: absolute;
  top: $global-spacing * 1.5;
  right: $global-spacing * 1.5;
  display: block;
  width: $global-spacing * 2.4;
  height: $global-spacing * 2.4;

  svg > * {
    display: block;
    width: 100%;
    height: 100%;
    fill: var(--royal-blue);
  }
}
