@import 'button/mixin.button';
@import 'button/mixin.icon';
@import 'button/size/mixin.small';
@import 'button/size/mixin.large';
@import 'button/variant/mixin.ghost';
@import 'button/variant/mixin.ghost-secondary';
@import 'button/variant/mixin.outline';
@import 'button/variant/mixin.primary';
@import 'button/variant/mixin.secondary';
@import 'button/variant/mixin.outline-primary';
@import 'button/variant/mixin.outline-secondary';

.c-button {
  @include button;
}

.c-button--full-width {
  width: 100%;
}

/**
* Button variants
*/
.c-button--primary {
  @include button--primary;
}

.c-button--secondary {
  @include button--secondary;
}

.c-button--ghost {
  @include button--ghost;
}

.c-button--ghost-secondary {
  @include button--ghost-secondary;
}

.c-button--outline {
  @include button--outline;
}

.c-button--outline-primary {
  @include button--outline-primary;
}

.c-button--outline-secondary {
  @include button--outline-secondary;
}

/**
* Button icon modifier
*/
.button--icon {
  @include button--icon;
}

/**
* Button sizes
*/
.button--small {
  @include button--small;
}

.button--large {
  @include button--large;
}
