$checkbox-size: 24px;
$checkbox-border-width: 2px;
$checkbox-border-radius: 3px;
$checkbox-color: var(--white);
$checkbox-label-color-tertiary: var(--charcoal-black);
$checkbox-label-text-color: var(--charcoal-black);
$checkbox-error-color: $color-danger;
$checkbox-margin-right: $global-spacing * 2;
$checkbox-transition-time: 200ms;
$checkbox-transition-ease: $ease-in-out-quad;

.checkbox__input {
  @include sr-only;

  &:checked {
    & + label {
      &::before {
        transform: scale(1);
      }

      &.checkbox__label--center {
        &::before {
          transform: scale(1) translateY(-50%);
        }
      }

      &::after {
        border-color: var(--button-background);
      }
    }
  }

  &[disabled] {
    & + label {
      cursor: default;

      &::after,
      &::before {
        opacity: 0.5;
      }
    }
  }

  &:invalid {
    outline: none;
  }
}

.checkbox__label {
  position: relative;
  display: block;
  cursor: pointer;
  padding-left: $checkbox-size + $global-spacing-xs;
  color: $checkbox-color;
  font-size: $font-size-small;
  line-height: $checkbox-size;

  &:not(:last-child) {
    margin-bottom: $global-spacing-xs;
  }

  &::before,
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: $checkbox-size;
    height: $checkbox-size;
    border-radius: $checkbox-border-radius;
    cursor: pointer;
  }

  &.checkbox__label--top {
    &::before,
    &::after {
      top: 0;
    }
  }

  &.checkbox__label--center {
    &::before,
    &::after {
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      transform: scale(0) translateY(-50%);
    }
  }

  &.checkbox__label--bottom {
    &::before,
    &::after {
      bottom: 0;
    }
  }

  &::before {
    transform: scale(0);
    transform-origin: center center;
    transition: $checkbox-transition-time $checkbox-transition-ease;
    transition-property: transform;
    background-color: var(--button-background);
    background-image: url('/assets/svg/icons/checkmark-white.svg');
    background-position: center center;
    background-repeat: no-repeat;
  }

  &::after {
    border: $checkbox-border-width solid;
    transition: $checkbox-transition-time $checkbox-transition-ease;
    transition-property: border-color;

    .form__item--error & {
      border-color: $checkbox-error-color;
    }
  }

  .form__item--primary & {
    &::after {
      border-color: var(--white);
    }
  }

  .form__item--secondary & {
    color: $checkbox-label-text-color;

    &::after {
      border-color: var(--royal-blue);
    }
  }

  .form__item--tertiary & {
    color: $checkbox-label-color-tertiary;

    &::after {
      border-color: var(--royal-blue);
    }
  }
}

.checkbox__error {
  margin: $global-spacing-sm 0 0;
  color: $color-danger;
  font-size: $font-size-small;
}
