.u-text--left {
  @include generate-mediaquery-rules {
    text-align: left;
  }
}

.u-text--centered {
  @include generate-mediaquery-rules {
    text-align: center;
  }
}

.u-text--right {
  @include generate-mediaquery-rules {
    text-align: right;
  }
}

.u-text--white {
  color: var(--white);
}

.u-text--extra-bold {
  font-weight: $font-weight-extrabold;
}

.u-text--primary {
  color: var(--secondary);
}

.u-text--secondary {
  color: var(--royal-blue);
}

.u-text--tertiary {
  color: var(--royal-blue);
}

.u-text--nonary {
  color: $color-black;
}

.u-text--red {
  color: $color-danger;
}
