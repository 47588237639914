.u-transition-height {
  transform-origin: center top;
  max-height: 1000px;
  transition: 300ms ease-in-out;
  transition-property: max-height, opacity;

  &--hidden {
    max-height: 0;
    pointer-events: none;
    opacity: 0;
  }
}
