$range-label-color: var(--charcoal-black);
$range-handle-color: var(--turquoise-green);

.range__wrapper {
  display: flex;

  .rangeslider {
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
    width: 100%;
    margin: 0 $global-spacing;
  }

  .rangeslider,
  .rangeslider .rangeslider__fill {
    display: block;
    height: 4px;
  }

  .rangeslider .rangeslider__handle {
    background: var(--white);
    border: 1px solid var(--royal-blue);
    cursor: pointer;
    display: inline-block;
    position: absolute;
    box-shadow: 0 2px 4px rgba($color-black, 0.24);
  }

  .rangeslider .rangeslider__handle .rangeslider__active {
    opacity: 1;
  }

  .rangeslider-horizontal {
    height: $global-spacing-md;
    padding: 14px 0;
    z-index: 0;

    &::before {
      content: '';
      position: absolute;
      top: 14px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: var(--royal-blue);
      display: block;
    }
  }

  .rangeslider-horizontal .rangeslider__fill {
    background-color: $range-handle-color;
    position: relative;
    z-index: 1;
  }

  .rangeslider-horizontal .rangeslider__handle {
    position: absolute;
    z-index: 1;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    box-shadow: 0 2px 6px rgba($color-black, 0.4);
  }

  .rangeslider-horizontal .rangeslider__handle:after {
    display: none;
  }

  .rangeslider-horizontal .rangeslider__handle-tooltip {
    position: relative;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $range-handle-color;
    border-radius: 3px;
    color: white;
    width: 40px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-small;
    pointer-events: none;

    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-top-color: $range-handle-color;
      border-width: 5px;
      margin-left: -$global-spacing-xs / 2;
    }
  }

  .rangeslider__labels {
    position: relative;
  }

  .rangeslider__labels .rangeslider__label-item {
    position: absolute;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    top: 10px;
    transform: translate3d(-50%, 0, 0);
  }
}

.range__value-label {
  position: relative;
  margin-bottom: $global-spacing-md;

  span {
    font-size: $font-size-body;
    line-height: $global-spacing-md;
    font-weight: $font-weight-bold;
  }

  span:first-child {
    display: block;
    width: 42px;
  }

  span:last-child {
    position: absolute;
    font-size: $font-size-small;
    top: $global-spacing-md;
    white-space: nowrap;
  }

  &--left {
    span:last-child {
      left: 0;
    }
  }

  &--right {
    span:last-child {
      right: 0;
    }
  }

  &--no-margin {
    margin-bottom: 0;
  }
}

.range__label-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: $global-spacing-sm;

  .c-tooltip {
    padding-left: $global-spacing;
  }
}

.range__label-title {
  display: block;
  font-size: $font-size-body;
  line-height: $font-line-height-body;
  font-weight: $font-weight-extrabold;
  color: $range-label-color;
}

.range__label-subtitle {
  display: block;
  font-weight: $font-weight-regular;
}

.range__error {
  margin: $global-spacing-sm 0 0;
  color: $color-danger;
  font-size: $font-size-small;
}
