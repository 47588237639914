$toggle-height: 24px;
$toggle-width: 48px;
$toggle-size: 16px;

$toggle-error-color: $color-danger;
$toggle-color: var(--button-background);
$toggle-label-color: var(--charcoal-black);

$toggle-transition-time: 200ms;
$toggle-transition-ease: $ease-in-out-quad;

.c-toggle {
  min-height: $toggle-height;
}

.toggle__input {
  @include sr-only;

  &:checked {
    & + label {
      &::before {
        transform: translateX(0);
      }

      &::after {
        background-color: $toggle-color;
      }
    }
  }

  &[disabled] {
    & + label {
      cursor: default;

      &::after,
      &::before {
        opacity: 0.5;
      }
    }
  }

  &:invalid {
    outline: none;
  }
}

.toggle__label {
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-right: $toggle-width + $global-spacing-sm;
  font-size: $font-size-small;
  min-height: 24px;

  &::before {
    content: '';
    position: absolute;
    top: #{$toggle-width / 12};
    right: #{$toggle-width / 12};
    width: $toggle-size;
    height: $toggle-size;
    background-color: var(--white);
    z-index: 1;
    border-radius: 100%;
    transform: translateX(-#{$toggle-width / 2});
    transition: $toggle-transition-time $toggle-transition-ease;
    transition-property: transform;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: $toggle-width;
    height: $toggle-height;
    border-radius: #{$toggle-height / 2};
    cursor: pointer;
    background-color: rgba(var(--royal-blue--rgb), 0.2);
    transition: $toggle-transition-time $toggle-transition-ease;
    transition-property: background-color;

    .form__item--error & {
      background-color: $toggle-error-color;
    }
  }
}

.toggle__label-title {
  font-weight: $font-weight-extrabold;
  color: $toggle-label-color;

  .form__item--secondary & {
    font-weight: $font-weight-regular;
  }
}

.toggle__unchecked-title,
.toggle__checked-title {
  color: $toggle-label-color;
  align-self: center;
  margin-left: auto;
  font-size: $font-size-small;
  font-weight: $font-weight-regular;
  transition: #{$toggle-transition-time / 2} $toggle-transition-ease;
  transition-property: opacity;
}

.toggle__error {
  margin: $global-spacing-sm 0 0;
  color: $color-danger;
  font-size: $font-size-small;
}
