.c-info-box {
  background-color: rgba(var(--white--rgb), 0.08);
  color: var(--left-side-text);
  border-radius: 8px;
  padding: $global-spacing * 1.6 $global-spacing * 2.4;
  margin-bottom: $global-spacing * 2.4;

  p {
    margin-bottom: 0;
  }
}

.info-box {
  &--dark {
    background-color: rgba(var(--royal-blue--rgb), 0.08);
    color: var(--royal-blue);
  }
}
