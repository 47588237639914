$header-height-mobile: 64px;
$header-height-desktop: 72px;

.o-content {
  padding-top: $header-height-mobile;

  @include screen-tablet-landscape-and-bigger {
    padding-top: $header-height-desktop;
  }
}
