.language_selector {
  position: absolute;
  top: 55px;
  left: 95px;
  padding: $global-spacing-xs * 1.5 $global-spacing-sm;
  display: flex;
  align-items: center;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .language_selector {
      position: absolute;
      top: 10px;
      left: 24px;
      padding: $global-spacing-xs * 1.5 $global-spacing-sm;
      display: flex;
      align-items: center;
    }
  }

@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) {
    .language_selector {
      position: absolute;
      top: 10px;
      left: 24px;
      padding: $global-spacing-xs * 1.5 $global-spacing-sm;
      display: flex;
      align-items: center;
    }
  }

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
    .language_selector {
      position: absolute;
      top: 53px;
      left: 96px;
      padding: $global-spacing-xs * 1.5 $global-spacing-sm;
      display: flex;
      align-items: center;
    }
  }