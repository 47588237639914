$input-border-width: 3px;
$input-border-radius: 32px;
$input-border-color: var(--white);
$input-border-tertiary-color: var(--charcoal-black);
$input-error-color: $color-danger;
$input-transition-time: 200ms;
$input-transition-ease: $ease-in-out-quad;
$lap-transition-time: 300ms;
$lap-transition-ease: ease;

.input__wrapper {
  position: relative;

  .is--loading & {
    &::after {
      content: '';
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1px dashed var(--white);
      background-color: transparent;
      top: 0;
      bottom: 0;
      right: 10px;
      display: block;
      position: absolute;
      margin: auto;
      animation: spin 2s infinite;
    }
  }
}

.input__label {
  display: block;
  color: var(--cloud-grey);
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate3d(15px, -50%, 0);
  transform-origin: 0 0;
  transition: $lap-transition-time $lap-transition-ease;
  transition-property: transform, color;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 $global-spacing-xs / 2;
  max-width: calc(100% - 30px);

  .form__item--primary & {
    background-color: var(--secondary);
  }

  .form__item--secondary & {
    background-color: var(--royal-blue);
  }

  .form__item--tertiary & {
    background-color: $input-border-color;
  }

  .form__item--verified & {
    background-color: var(--cloud-grey);
  }

  .form__item--active.form__item--tertiary & {
    color: $input-border-tertiary-color;
  }

  .form__item--active.form__item--verified & {
    color: var(--royal-blue);
  }

  .form__item--error.form__item--tertiary & {
    color: $color-danger;
  }

  .form__item--active & {
    transform: translate3d(15px, -35px, 0);
    color: var(--white);
  }

  .form__item--error & {
    color: $color-danger;
  }
}

.input__input {
  display: block;
  appearance: none;
  width: 100%;
  background-color: transparent;
  transition: border-color $input-transition-time $input-transition-ease;
  padding: $global-spacing-xs * 1.5 $global-spacing-sm;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  color: var(--cloud-grey);
  max-height: $global-spacing-xlg;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  .form__item--tertiary & {
    border-color: $input-border-tertiary-color;
    color: $input-border-tertiary-color;
  }

  .form__item--verified & {
    border-color: var(--secondary);
  }

  .form__item--active.form__item--tertiary & {
    border-color: $input-border-tertiary-color;
    color: $input-border-tertiary-color;

    &:disabled {
      opacity: 1;
      color: var(--charcoal-black);
      border-color: var(--cloud-grey);
      -webkit-text-fill-color: currentcolor;
    }
  }

  .form__item--active.form__item--verified & {
    border-color: var(--secondary);
    color: var(--royal-blue);
  }

  .form__item--error.form__item--tertiary & {
    border-color: $color-danger;
  }

  .form__item--active & {
    border-color: rgba($input-border-color, 1);
    color: var(--white);
  }

  .form__item--error & {
    border-color: $color-danger;
  }
}

.input__clear,
.input__tooltip {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
}

.input__error {
  margin: $global-spacing-xs 0 0 $global-spacing-xs * 1.5;
  color: $color-danger;
  font-size: $font-size-small;
}

.input__prefix {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 6px;
  opacity: 0;
  color: var(--normal-text);
  transition: opacity 300ms ease-in-out;

  .form__item--active & {
    opacity: 1;
  }
}

.input__suffix {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  right: 15px;
  color: var(--normal-text);
}

.input__small {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: -20px;

  .form__item_socmed {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }

  .form__item_socmed > div {
    width: 100%;
    margin-right: 10px;
  }

  @include screen-tablet-portrait-and-bigger {
    flex-wrap: nowrap;

    .form__item_socmed {
      width: 100%;

      &:not(:last-child) {
        margin-right: $global-spacing * 2;
      }
    }
    .form__item_socmed > div {
      width: 100%;
    }
  }
}

.button__social-media button {
  background-color: #50e96f;
  color: #ffffff;
  margin-bottom: 5%;
  font-size: 16px;
}

.button__social-media button:hover {
  background-color: var(--turquoise-green, #5cb8a5) !important;

  color: #ffffff;
}

.form__item_socmed button {
  margin-right: auto; /* Push the button to the right edge */
  background-color: #CF0404; /* Add your desired button background color */
  color: #ffffff; /* Add your desired button text color */
  border: none;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: 1%;
  margin-left: 1%;
  padding: 1% 2%;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
}

.form__item_socmed button:hover {
  background-color: #AB0505; /* Add your desired button background color */
}

.form__item_socmed_button_add {
  background-color: #50e96f !important;
}

.form__item_socmed_button_add:hover {
  background-color: #37bb52 !important;
}

.form__item_socmed_warning {
  margin-top: -5%;
  margin-bottom: 5%;
}