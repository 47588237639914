.collapse__wrapper {
  position: relative;
  overflow: hidden;
  max-height: 10000px;
  transition: max-height 300ms;
  transition-timing-function: $ease-in-out-cubic;
}

.collapse__wrapper-active {
  border-bottom: 1px solid rgba(10, 74, 142, 0.24);
}
