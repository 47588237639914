/*----------------------------------*\
  #TYPOGRAPHY
\*----------------------------------*/

// Font stacks

:root,
:root.bluebeem {
  --font: 'Nunito', Arial, Helvetica, sans-serif, 'Twemoji Country Flags';
}

:root.brinks {
  --font: 'NunitoSans', Arial, Helvetica, sans-serif, 'Twemoji Country Flags';
}

$font-stack-primary: var(--font);

// Font settings

$font-stack-body: $font-stack-primary;
$font-stack-buttons: $font-stack-primary;

// Font sizes

$font-size-root: 10px; // The font size set on the root html element.

$font-size-body: 1.8rem; // Regular font size

$font-size-h1: 7.2rem;

$font-size-h2: 4.8rem;

$font-size-h3: 3.6rem;

$font-size-h4: 2.4rem;

$font-size-h5: 2.4rem;

$font-size-h6: 1.8rem;

$font-size-small: 1.4rem;

$font-size-caption: 1.2rem;

// Font line heights
// Divide the the given line height by the font size to give an unitless line height
// @example {design line height} / {font size} = {unitless lineheight}
//
// NOTE: Never use px as line-height value!!!

$font-line-height-body: 1.33; // {design-line-height} / strip-unit($font-size-body);

$font-line-height-h1: 1.11; // {design-line-height} / strip-unit($font-size-h1);

$font-line-height-h2: 1; // {design-line-height} / strip-unit($font-size-h2);

$font-line-height-h3: 1; // {design-line-height} / strip-unit($font-size-h3);

$font-line-height-h4: 1; // {design-line-height} / strip-unit($font-size-h4);

$font-line-height-h5: 1.33; // {design-line-height} / strip-unit($font-size-h5);

$font-line-height-h6: 1.33; // {design-line-height} / strip-unit($font-size-h6);

// Font weights
$font-weight-ultralight: 100;

$font-weight-light: 200;

$font-weight-book: 300;

$font-weight-regular: 400;

$font-weight-medium: 500;

$font-weight-semibold: 600;

$font-weight-bold: 700;

$font-weight-extrabold: 800;

$font-weight-heavy: 900;
