.u-background--primary {
  background-color: var(--secondary);
}

.u-background--secondary {
  background-color: var(--royal-blue);
}

.u-background--tertiary {
  background-color: var(--royal-blue);
}
