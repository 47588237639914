$link-transition-time: 200ms;
$link-transition-ease: $ease-in-out-quad;

// most basic link style
@mixin link--base {
  color: var(--white);
  font-weight: $font-weight-bold;
  text-decoration: none;
  cursor: pointer;
  text-overflow: ellipsis;
  transition: $link-transition-time $link-transition-ease;
  transition-property: border, color, background-color;
}

@mixin text--underline($hover: true, $height: 1px, $shadow-color: false, $position: 0) {
  position: relative;
  text-decoration: none;
  z-index: 0;

  @if $shadow-color !=false {
    text-shadow:
      -2px -1px 0 $shadow-color,
      2px -1px 0 $shadow-color,
      -2px 1px 0 $shadow-color,
      2px 1px 0 $shadow-color;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: $position;
    left: 0;
    background-color: currentColor;
    width: 100%;
    height: $height;
    transform-origin: left center;
    transform: scaleX(0);
    transition: 300ms transform;
    z-index: -1;
  }

  @if $hover==true {
    @include hover-focus {
      outline: none;

      &::before {
        transform: scaleX(1);
      }
    }

    @include keyboard-focus {
      &::before {
        transform: scaleX(1);
      }
    }
  }

}

a {
  @include link--base;
  @include text--underline;
}

.has--keyboard-focus {
  a:focus {
    position: relative;
    @include focus-state-pulse;
  }
}
