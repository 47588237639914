// Style
$button-ghost-spacing: $global-spacing;
$button-ghost-border-width: 2px;
$button-ghost-border-radius: 3px;

// Transitions
$button-ghost-transition-time: $button-transition-time;
$button-ghost-transition-ease: $button-transition-ease;

@mixin button--outline-primary {
  @include button--primary;
  background: transparent;
  box-shadow: 0 0 0 $button-ghost-border-width var(--secondary) inset;
  transition-property: color, background-color, box-shadow;
  color: var(--royal-blue);
  font-weight: $font-weight-heavy;

  // @include hover-focus {
  //   color: var(--white);
  //   background-color: var(--secondary);
  //   box-shadow: 0 0 0 $button-ghost-border-width var(--secondary) inset;
  // }

  // @include keyboard-focus {
  //   background-color: makeLighter(var(--secondary), 50%);
  //   box-shadow: 0 0 0 $button-ghost-border-width makeLighter(var(--secondary), 50%) inset;
  // }
}
