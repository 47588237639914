$offset: 24px;
$gutter: map_get($grid-config, 'container-gutter');
$gutter-small: map_get($grid-config, 'container-gutter-small');
$container-width: map_get($grid-config, 'max-width');

.c-form-layout {
  position: relative;
  background-color: var(--main-layout);
  min-height: 100vh;
  overflow: auto;

  @include screen-tablet-portrait-and-bigger {
    &::after {
      content: '';
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 0 24px var(--main-layout) inset;
      pointer-events: none;
      z-index: 3;
    }

    &::before {
      content: '';
      background-color: var(--right-layout);
      position: absolute;
      top: 0;
      right: 0;
      width: 50vw;
      height: 100%;
    }
  }

  > * {
    position: relative;
  }

  @include screen-mobile-plus-and-smaller {
    &.form-layout--mobile-reverse {
      &::before {
        left: 0;
        right: auto;
      }

      .o-grid {
        flex-direction: column-reverse;
      }
    }
  }
}

.form-layout__column {
  padding-top: $global-spacing * 12;
  padding-bottom: $global-spacing * 4.8;

  @include screen-mobile-plus-and-smaller {
    &--two {
      width: calc(100% + #{$offset * 2});
      margin-left: -$offset;
      margin-right: -$offset;
      padding: $global-spacing-md $offset $global-spacing-lg;
      background-color: var(--right-layout);
    }

    &--mobile {
      padding: 0;
    }
  }

  @include screen-tablet-portrait-and-bigger {
    flex-basis: 50%;
    padding: $global-spacing * 2.4;
    padding-top: $global-spacing * 6.8;
    z-index: 1;

    &--two {
      z-index: 0;
    }
  }

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing * 9.6;
    padding-top: $global-spacing * 14.4;
  }
}
