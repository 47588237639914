$cash-cards-clicks-background-color: var(--white);
$cash-cards-clicks-title-color: var(--navy-blue);
$cash-cards-clicks-border-color: var(--royal-blue--rgb);

.c-cash-cards-clicks {
  border-radius: 8px;
  width: 100%;
  border: 1px solid rgba($cash-cards-clicks-border-color, 0.24);
  background-color: $cash-cards-clicks-background-color;
  margin-bottom: $global-spacing-sm;

  .form__item {
    margin-bottom: 0;
  }
}

.cash-cards-clicks__item {
  padding: $global-spacing-sm map-get($grid-config, 'container-gutter') 0;

  &::after {
    content: '';
    display: block;
    padding-top: $global-spacing-sm;
  }

  &:not(:last-child)::after {
    border-bottom: 1px solid rgba(var(--royal-blue--rgb), 0.24);
  }
}

.cash-cards-clicks__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 500px;
  transition: 300ms ease-in-out;
  transition-property: opacity, max-height, padding;

  .c-select {
    max-width: calc(50% - 10px);
  }

  &.cash-cards-clicks__row--is-hidden {
    opacity: 0;
    max-height: 0;
    padding: 0;
  }
}

.cash-cards-clicks__checkbox-wrapper {
  padding: $global-spacing-sm $global-spacing-xs * 2.4;
  position: relative;

  .c-select {
    max-width: calc(50% - 10px);
  }

  .c-checkbox {
    width: 100%;

    .checkbox__wrapper {
      margin-bottom: 0;
    }
  }

  &::after {
    content: '';
    width: calc(100% - 48px);
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    border-bottom: 1px solid rgba(var(--royal-blue--rgb), 0.24);
  }
}

.cash-cards-clicks__row--padding-top {
  padding-top: $global-spacing-sm;
}

.cash-cards-clicks__title {
  display: block;

  &.cash-cards-clicks__title--primary {
    color: var(--royal-blue);
    background-color: rgba(var(--royal-blue--rgb), 0.08);
    padding: $global-spacing-sm;
  }
}

.cash-cards-clicks__main-title {
  display: block;
  font-size: $font-size-body;
  line-height: $font-line-height-body;
  font-weight: $font-weight-extrabold;

  .cash-cards-clicks__title--tertiary & {
    padding-left: $global-spacing-sm;

    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      left: 0;
      top: calc(#{($font-line-height-body) / 2}em - 4px);
      display: inline-block;
      border-radius: 100%;
      background-color: var(--secondary);
    }
  }

  .cash-cards-clicks__title--secondary &,
  .cash-cards-clicks__title--tertiary & {
    color: $cash-cards-clicks-title-color;
    position: relative;
  }
}

.cash-cards-clicks__button-toggle {
  display: flex;
  align-items: center; /* Vertically align items */
  justify-content: space-between; /* Space evenly between items */
  border-bottom: 1px solid rgba(var(--royal-blue--rgb), 0.24);
}

.cash-cards-clicks__sub-title {
  position: relative;
  display: block;
  font-size: $font-size-body;
  line-height: $font-line-height-body;
  color: $cash-cards-clicks-title-color;

  .cash-cards-clicks__title--tertiary & {
    padding-left: $global-spacing-sm;
  }
}

.cash-cards-clicks__error {
  margin: $global-spacing-sm 0 0;
  color: $color-danger;
  font-size: $font-size-small;
}

.cash-cards-clicks__group-button-wrapper {
  position: relative;
  padding: $global-spacing-sm map-get($grid-config, 'container-gutter');
}

.cash-cards-clicks__group-button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: $color-success;
  width: 24px;
  height: 24px;

  svg {
    width: 11px;
    height: 11px;

    & * {
      fill: var(--white);
    }
  }

  .cash-cards-clicks__group-button-wrapper--error & {
    background-color: $color-danger;
  }
}

.cash-cards-clicks__group-button {
  @include h3;
  background-color: transparent;
  transition: background-color 100ms $ease-in-cubic;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-weight: $font-weight-bold;
  color: $cash-cards-clicks-title-color;
}

.cash-cards-clicks__group-button-toggle {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  padding-right: 5px;
}

.cash-cards-clicks__group-button-chevron {
  display: block;
  transform: rotate(0deg);
  transition: transform 300ms $ease-in-cubic;

  .cash-cards-clicks__group-button--active & {
    transform: rotate(180deg);
  }
}

.c-cash-cards-clicks_checkbox {
  margin: $global-spacing-md 0;
}

.cash-cards-clicks__submit-button {
  margin-bottom: 20px;
}

.cash-cards-clicks__revenue {
  margin-top: 20px;

  .product-input__label-wrapper {
    margin-bottom: 10px;
  }
}

.cash-cards-clicks__revenue-total {
  padding: 20px 20px 0;
  color: var(--royal-blue);
  display: flex;
  align-items: flex-end;
}

.cash-cards-clicks__revenue-total-amount {
  font-weight: $font-weight-extrabold;
}
