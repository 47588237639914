// Version: 2.8.4
.tns-outer {
  padding: 0 !important; // sass-lint:disable-line no-important

  [hidden] {
    display: none !important; // sass-lint:disable-line no-important
  }

  [aria-controls],
  [data-action] {
    cursor: pointer;
  }
}

.tns-slider {
  transition: all 0s; // sass-lint:disable-line no-transition-all

  > div,
  > li,
  > a {
    box-sizing: border-box;
  }
}

.tns-horizontal {
  &.tns-subpixel {
    white-space: nowrap;

    > div,
    > li,
    > a {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
    }
  }

  &.tns-no-subpixel {
    > div,
    > a,
    > li {
      float: left;
      margin-left: 0 !important; // sass-lint:disable-line no-important
    }
  }
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px; // make sure slider container is visible

  > div,
  > li {
    position: absolute;
    left: -100%;
    transition: transform 0s, opacity 0s;
  }

  > .tns-moving {
    transition: all 0.25s; // sass-lint:disable-line no-transition-all
  }
}

.tns-lazy-img {
  transition: opacity 0.6s;
  opacity: 0.6;

  &.loaded {
    opacity: 1;
  }
}

.tns-ah {
  transition: height 0s;
}

.tns-ovh {
  overflow: visible;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

// sass-lint:disable class-name-format
.tns-fadeIn {
  opacity: 1;
  z-index: 0;
}

.tns-normal,
.tns-fadeOut {
  opacity: 0;
  z-index: -1;
}
// sass-lint:enable class-name-format

// *** Detecting browser capability ***
$width: 310px;
$height: 10px;
$count: 70;
$perpage: 3;

.tns-t {
  &-subp2 {
    margin: 0 auto;
    width: $width;
    position: relative;
    height: $height;
    overflow: hidden;
  }

  // sass-lint:disable no-duplicate-properties
  &-ct {
    width: (100% * $count / $perpage);
    width: -webkit-calc(100% * #{$count} / #{$perpage});
    width: -moz-calc(100% * #{$count} / #{$perpage});
    width: calc(100% * #{$count} / #{$perpage});
    position: absolute;
    right: 0;

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    > div {
      width: (100% / $count);
      width: -webkit-calc(100% / #{$count});
      width: -moz-calc(100% / #{$count});
      width: calc(100% / #{$count});
      height: $height;
      float: left;
    }
  }
  // sass-lint:enable no-duplicate-properties
}
