$tooltip-color: var(--charcoal-black);

.tooltip__icon {
  transform: rotate(180deg);

  svg > * {
    fill: $tooltip-color;
  }
}

.tooltip__text {
  min-width: 200px;
  max-width: 350px;

  .c-tooltip--primary & {
    background-color: $tooltip-color;
    color: var(--white);

    &::after {
      border-top-color: transparent !important; // sass-lint:disable-line no-important
      border-left-color: $tooltip-color !important; // sass-lint:disable-line no-important
    }
  }

  .c-toolitp--secondary & {
    background-color: var(--white);
    color: $tooltip-color;

    &::after {
      border-top-color: transparent !important; // sass-lint:disable-line no-important
      border-left-color: var(--white) !important; // sass-lint:disable-line no-important
    }
  }
}
