.accessories-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 800;
  line-height: 1.33;
  color: #0a4a8e;
  margin-bottom: 15px;
}

.accessory {
  margin-right: $global-spacing-sm;
  height: 280px;
  border-radius: 12px;
  border: solid 1px rgba(#0a4a8e, 0.24);
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.accessory-img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.accessory-name {
  font-size: 18px;
  line-height: 1.33;
  color: #0a4a8e;
  padding: 12px 18px;
  text-align: center;
  height: 87px;
}

.accessory-price {
  font-size: 18px;
  font-weight: 800;
  line-height: 1.33;
  color: #0a4a8e;
}

.accessory-quantity {
  width: 100%;
  height: 48px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: var(--button-background);
  position: absolute;
  bottom: -2px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 800;
  line-height: 1.33;
  color: #fef9ec;
}

.accessory-quantity-buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accessory-quantity-minus-plus {
  border: 2px solid #fef9ec;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accessory-slider-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40px;

  @include screen-tablet-landscape-and-smaller {
    margin: 20px 24px 0 0;
  }
}
